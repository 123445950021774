import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['spinner'];

  /**
   * Disconnect the controller.
   */
  disconnect() {
    this.spinnerTarget.classList.remove('flex');
    this.spinnerTarget.classList.add('hidden');
  }

  /**
   * Show spinner.
   */
  showSpinner() {
    this.spinnerTarget.classList.remove('hidden');
    this.spinnerTarget.classList.add('flex');
  }
}

// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-32x32.png';
import '../assets/img/favicon/favicon-16x16.png';
import '../assets/img/svg/logo.svg';
import '../assets/img/svg/logo-vu.svg';
import '../assets/img/svg/logo-endoor.svg';
import '../assets/img/svg/shape.svg';
import '../assets/img/svg/pattern.svg';

// Icons
import '../assets/img/svg/icon-chevron.svg';
import '../assets/img/svg/icon-close.svg';
import '../assets/img/svg/icon-menu.svg';
import '../assets/img/svg/icon-play.svg';
import '../assets/img/svg/icon-plus.svg';
import '../assets/img/svg/icon-search.svg';
import '../assets/img/svg/icon-user.svg';
import '../assets/img/svg/icon-close-bold.svg';
import '../assets/img/svg/icon-heart.svg';
import '../assets/img/svg/icon-list-down.svg';
import '../assets/img/svg/icon-heart-outline.svg';
import '../assets/img/svg/icon-information.svg';
import '../assets/img/svg/icon-email.svg';
import '../assets/img/svg/icon-phone.svg';
import '../assets/img/svg/icon-quote.svg';
import '../assets/img/svg/icon-whatsapp.svg';
import '../assets/img/svg/icon-share.svg';
import '../assets/img/svg/icon-spinner.svg';
import '../assets/img/svg/icon-filter.svg';
import '../assets/img/svg/icon-arrow.svg';
import '../assets/img/svg/icon-check.svg';
import '../assets/img/svg/icon-cross.svg';
import '../assets/img/svg/icon-bike.svg';
import '../assets/img/svg/icon-train.svg';
import '../assets/img/svg/icon-car.svg';
import '../assets/img/svg/icon-pin.svg';
import '../assets/img/svg/icon-compare.svg';
import '../assets/img/svg/icon-calendar.svg';
import '../assets/img/svg/icon-clock.svg';
import '../assets/img/svg/icon-external.svg';
import '../assets/img/svg/icon-chart.svg';
import '../assets/img/svg/icon-marker.svg';
import '../assets/img/svg/icon-money.svg';
import '../assets/img/svg/icon-dislike.svg';
import '../assets/img/svg/icon-like.svg';
import '../assets/img/svg/icon-pdf.svg';
import '../assets/img/svg/icon-min.svg';
import '../assets/img/svg/icon-opendag.svg';
import '../assets/img/svg/locale-de.svg';
import '../assets/img/svg/locale-nl.svg';
import '../assets/img/svg/locale-en.svg';
import '../assets/img/svg/drag-chevron-left.svg';
import '../assets/img/svg/icon-drag.svg';
import '../assets/img/svg/icon-facebook.svg';
import '../assets/img/svg/icon-instagram.svg';
import '../assets/img/svg/icon-linkedin.svg';
import '../assets/img/svg/icon-tiktok.svg';
import '../assets/img/svg/icon-link.svg';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Start Turbo
Turbo.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}

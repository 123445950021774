/* eslint-disable class-methods-use-this */
/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Navigation controller
 */
export default class extends Controller {
  static targets = ['input', 'items', 'favorites'];

  static classes = ['active'];

  /**
   * Toggle target class.
   *
   * @param {Event} event
   */
  toggle(event) {
    event.preventDefault();
    this.element.classList.toggle(this.activeClass);

    if (this.hasInputTarget) this.inputTarget.focus();
  }

  /**
   * Show favorites.
   *
   * @param {Event} e
   */
  showFavorites(e) {
    if (!this.element.classList.contains(this.activeClass)) {
      this.toggle(e);
    }
    this.toggleVisibility(this.itemsTarget, this.favoritesTarget);
    this.favoritesTarget.reload();
  }

  /**
   * Show nav items.
   *
   * @param {Event} e
   */
  showItems(e) {
    this.toggle(e);
    this.toggleVisibility(this.favoritesTarget, this.itemsTarget);
  }

  /**
   * Toggle visibility between two targets.
   *
   * @param {HTMLElement} hideTarget
   * @param {HTMLElement} showTarget
   */
  toggleVisibility(hideTarget, showTarget) {
    hideTarget.classList.add('hidden');
    hideTarget.classList.remove('flex');
    showTarget.classList.add('flex');
    showTarget.classList.remove('hidden');
  }
}

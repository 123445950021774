import { Controller } from '@hotwired/stimulus';
import Cookie from '../cookie';

/**
 * Favorites controller
 */
export default class extends Controller {
  static targets = ['count'];

  /**
   * Cookie storage key
   *
   * @type {String}
   */
  storageKey = 'educationFavorites';

  /**
   * Connect controller.
   */
  connect() {
    this.updateCount(this.list().size);
  }

  /**
   * Update favorites cookie.
   *
   * @param {Set} list
   */
  update(list) {
    Cookie.set(this.storageKey, Array.from(list).join(','));
    this.updateCount(list.size);
    this.dispatch('updated', { detail: { list } });
  }

  /**
   * Update count.
   *
   * @param {Number} count
   */
  updateCount(count) {
    if (count > 0) {
      this.countTarget.innerText = count;
      this.countTarget.classList.remove('hidden');
      this.countTarget.classList.add('flex');
    } else {
      this.countTarget.classList.add('hidden');
      this.countTarget.classList.remove('flex');
    }
  }

  /**
   * Get favorites list.
   *
   * @return {Set}
   */
  list() {
    const cookie = Cookie.get(this.storageKey);
    return new Set(cookie ? cookie.split(',') : []);
  }
}

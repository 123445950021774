/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    text: String,
  };

  static targets = ['text'];

  /**
   * Copy text to clipboard
   *
   * @param {Event} event
   */
  async copy(event) {
    await navigator.clipboard.writeText(event.params.text);

    if (this.hasTextValue && this.hasTextTarget) {
      const originalText = this.textTarget.textContent;
      this.textTarget.textContent = this.textValue;

      setTimeout(() => {
        this.textTarget.textContent = originalText;
      }, 1000);
    }
  }
}

import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

/**
 * Favorite controller
 */
export default class extends Controller {
  static values = {
    id: Number,
  };

  static targets = ['active', 'inactive'];

  static class = 'favorited';

  get favorites() {
    return this.application.getControllerForElementAndIdentifier(document.body, 'favorites');
  }

  /**
   * Connect controller.
   */
  connect() {
    this.update();
  }

  /**
   * Update state.
   */
  update() {
    this.element.classList.toggle(
      this.constructor.class,
      this.favorites.list().has(String(this.idValue)),
    );
  }

  /**
   * Toggle favorite.
   */
  toggle() {
    const id = this.idValue;
    const list = this.favorites.list();

    if (list.has(String(id))) {
      list.delete(String(id));
      this.element.classList.remove(this.constructor.class);
    } else {
      list.add(String(id));
      this.element.classList.add(this.constructor.class);
    }
    this.favorites.update(list);
  }

  /**
   * Remove favorite.
   *
   * @param {Event} e
   */
  remove(e) {
    const id = this.idValue;
    const list = this.favorites.list();

    list.delete(String(id));
    this.favorites.update(list);
    this.element.parentNode.removeChild(this.element);
    Turbo.visit(e.params.url.toString(), { frame: 'favorites' });
  }
}
